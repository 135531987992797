/* Facturatie */
#facturatieWrapper {
	font-size: 12px;
}

#facturatieWrapper .contentWrapper {
	margin: auto;
	width: 1024px;
	padding: 5px 5px 25px 5px;
}

#facturatieWrapper .btn-dark {
	border: 0;
	font-weight: normal;
	background: #555;
	color: #fff;
}
#facturatieWrapper .btn-light {
	border: 0;
	font-weight: normal;
	background: #166ecc;
	width: 125px;
	color: #fff;
}

#facturatieWrapper #facturatieFilterForm select {
	width: 305px;
	margin-right: 5px;
}

#facturatieWrapper #facturatieFilterForm select.smallSelect {
	width: 60px;
}

#facturatieWrapper #facturatieFilterForm select.last {
	width: 235px;
	margin-right: 0px;
}

#facturatieWrapper #ignoreCacheInputBox {
	width: 25px;
}

#facturatieWrapper #ignoreCacheLabel {
	width: 100px;
	float: none;
	display: inline;
}

#facturatieWrapper .highlight,
#facturatieWrapper .highlightTable {
	clear: both;
	border: 1px solid #ddd;
	padding: 10px;
	overflow: hidden;
	font-size: 14px;
	font-weight: bold;
	background-color: #f3f3f3;
}

#facturatieWrapper .highlight {
	margin-bottom: 20px;
}

#facturatieWrapper .highlightTable {
	border-bottom: 0;
}

#facturatieWrapper #facturatieTotal p {
	padding: 10px;
}

#facturatieWrapper .customerTotal {
	margin: 10px 10px 20px;
}

#facturatieWrapper .customerTotal input {
	margin-top: 0;
}

#facturatieWrapper .projectBlock {
	margin-bottom: 50px;
}

#facturatieWrapper .ticketStatus a.default,
#facturatieWrapper .ticketStatus a.open,
#facturatieWrapper .ticketStatus a.unresolved,
#facturatieWrapper .ticketStatus a.new,
#facturatieWrapper .ticketStatus a.pending {
	color: $color-red;
	text-decoration: underline;
}

#facturatieWrapper .ticketStatus a.resolved,
#facturatieWrapper .ticketStatus a.closed {
	color: $color-green;
}

#handledItems {
	background-color: $color-green;
	border: 1px solid $color-green;
	padding: 10px;
	margin-bottom: 10px;
}

#handledItemsError {
	background-color: $color-red;
	border: 1px solid $color-red;
	padding: 10px;
	margin-bottom: 10px;
	color: #ffffff;
}

#facturatieWrapper .facturatieTable {
	width: 100%;
}

#facturatieWrapper .facturatieTable select {
	margin: 0;
	width: auto;
}

#facturatieWrapper .facturatieTable,
#facturatieWrapper .facturatieTable th,
#facturatieWrapper .facturatieTable tr,
#facturatieWrapper .facturatieTable td {
	border: 1px solid #ddd;
}

#facturatieWrapper .facturatieTable tr {
	vertical-align: top;
}

#facturatieWrapper .facturatieTable th,
#facturatieWrapper .facturatieTable td {
	word-wrap: break-word;
	padding: 4px;
}

#facturatieWrapper .facturatieTable th {
	font-size: 13px;
	line-height: 2;
}

#facturatieWrapper .facturatieTable td.timeRegistrationDate {
}

#facturatieWrapper .facturatieTable input.invoiceHours {
	width: 50px;
	margin: 0;
	padding: 3px;
}

#facturatieWrapper .facturatieTable label {
	width: auto;
	float: none;
	display: inline;
}

#facturatieWrapper .readOnly,
#facturatieWrapper .readOnly textarea,
#facturatieWrapper .readOnly input {
	color: #999;
}

#facturatieWrapper .rowIcons input[type="radio"] {
	margin: 3px;
	width: 10px;
}

#facturatieZoekFilter {
	margin: 0 0 20px 0;
}

#facturatieZoekFilter input {
	width: 300px;
}

#facturatieZoekFilter .searchButton {
	width: 110px;
}

#facturatieWrapper span.error {
	color: $color-red;
	font-weight: bold;
}

#facturatieWrapper .facturatieTable .headerRowIcons {
	width: 75px;
}

#facturatieWrapper .comDescription {
	width: 300px;
	max-width: 300px;
	height: 75px;
	display: block;
	margin-top: 0;
}

#facturatieWrapper .generateInvoiceDisabled {
	cursor: default;
	background: #eee;
	color: #999;
}