/**
 * General site components
 */

@import "header";
@import "footer";
@import "login";
@import "content";
@import "calendar";
@import "projects";
@import "planning";
@import "dashboard";
@import "facturatie";