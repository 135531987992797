/**
 * Login
 */

.login {
	background-color: $color-gray-light;

	.login__login--panel {
		padding-top: 50px;
	}

	.logo {
		margin: 0 auto;
	}

	form {
		border: 1px solid $color-gray-medium;
		min-width: 250px;
		width: 30%;
		margin: 40px auto;
		padding: 25px;
		font-size: 120%;
		background: $color-white;
		text-align: center;

		input {
			width: 200px;
		}

		.stay {
			display: block;
			margin: 0 auto;
		}

		.submit {
			width: 120px;
			margin: 20px auto 0;
		}

		.checkbox {
			width: auto;
			margin-left: 30%;
		}

		label {
			width: 30%;
		}
	}
}

#gSignInWrapper {
	margin-top: 125px;
	width: 400px;
	position: absolute;
	left: 50%;
	margin-left: -200px;
    font-size: 14px;
}

.loginButton {
	text-align: center;
	margin-top: 30px;
	h1 {
		text-align: center;
		font-size: 22px;
		color: #999;
		margin-bottom: 25px;
	}
}

#gooleLoginButton {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}
#gooleLoginButton:hover {
  cursor: pointer;
}
span.label {
  font-weight: normal;
}
span.icon {
  background: url('/assets/img/g-normal.png') transparent 5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}
span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: 14px;
  font-weight: bold;
}