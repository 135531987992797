/**
 * Planning
 */

/* Planning Table */
.planningFilter {
    legend {
        display: inline;
        float: left;
        padding: 0.2em;
        margin: 0.5em 5px 0 0;
    }

    select {
        color: #333;
        border-color: #7f8c8d;
        -webkit-appearance: none;
        border-radius: 0;
        width: 150px;
        margin-right: 0;
        background: #eee url('/images/icons/caret-down.png') no-repeat right
            center;
        background-size: 15px 7px;
    }

    .select-small {
        width: 80px;
    }

    .clearFilter {
        color: #666;
        border: 1px solid #7f8c8d;
        background-color: #eee;
        float: left;
        margin: 0.5em 10px 0 0;
        padding: 0.2em;
        text-decoration: none;
    }

    .clearFilter:hover {
        color: #333;
        text-decoration: underline;
    }

    .btnPrimary {
        float: none;
    }
}

/* Planning overview S&S input fields */
.sensInsert {
    width: 36px;
    height: 43px;
    padding: 0 !important;
}

.sensInsert input {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
    border: 0;
    float: left;
}

.thisWeek input {
    background: #e1e1e1;
}

#planningWrapper {
    //width: 1259px;
    margin: 0;
    overflow: hidden;
    position: relative;
}

.planningTable {
    width: 1263px;
    border: 1px solid #aaa;
}

.planningTable th,
.planningTable td {
    padding: 7px;
    border: 1px solid #ccc;
    border-top: none;
    border-bottom: 0;
    text-align: center;
}

.planningTable th:last-child,
.planningTable td:last-child {
    border-right: none;
}

.planningTable tr:hover {
    background-color: #dfdfdf;
}

.planningTable .icon {
    position: absolute;
    left: 205px;
}

.planningTable tr.active:hover td,
.planningTable .active td {
    border: none;
    background: #666;
}

.planningTable .active .stuffed {
    background: #666;
}

.planningTable .stuffed {
    background: #d5dd4c;
    border-color: #d5d54c;
    color: #444;
}

.planningTable tr:hover .stuffed {
    background: #c3c300;
}

.planningTable .expand td:first-child {
    padding-left: 30px;
}

.planningTableNoPadding td:first-child {
    padding-left: 0;
}

.planningTable .thisWeek {
    border: 2px solid #aaa;
    border-top: 0;
    border-bottom: 0;
    font-weight: bold;
}

.planningTable #planningTableHeader {
    z-index: 1;
    margin-top: -125px;
}

.planningTable tr td:first-child {
    text-align: left;
}

.planningTable th.firstTd,
.planningTable td.firstTd {
    text-align: left;
    padding-left: 10px;
    min-width: 220px;
    max-width: 220px;
}

.planningTable th.firstTd {
    text-align: left;
    border-color: #fff;
    background: #fff;
}

.planningTable .firstTd .projectAnchor {
    width: 155px;
    display: block;
    float: left;
}

.planningTable .firstTd .projectAnchorPlanning {
    width: 145px;
    float: left;
}

.planningTable .largeTd {
    min-width: 40px;
    max-width: 40px;
    padding: 0 5px;
    color: #666;
}

.planningTable .active .largeTd {
    color: #fff;
}

.planningTable .darkBorderRight {
    border-right: 1px solid #999;
}

.planningTable th.largeTd {
    border-color: #fff;
    background: #fff;
}

.planningTable .weekcell {
    min-width: 22px;
    max-width: 22px;
    padding: 0 7px;
}

.planningTable th.weekcell {
    border-color: #fff;
    background: #fff;
}

.planningTable .weekcell a {
    display: block;
}

.planningTable .blue {
    color: #00f;
}

.planningTable .red {
    color: $color-red;
}

.planningTable .weeklyUpdateCheckbox {
    width: 13px;
}

.planningTable .success {
    background-color: green;
    z-index: 50;
    cursor: pointer;
}

#planningTableHeader {
    border-bottom: 1px solid #666;
}

.planningTable tr.recurring-number-summary {
    background-color: #e1e1e1;
    border: 1px solid #aaa;
}

.fixed {
    padding-left: 8px;
    top: 0;
    background: #fff;
    z-index: 999;
}

.topMargin {
    margin-top: 30px;
}

.formNoPadding {
    padding: 0;
    font-size: 110%;
}

.formNoPadding td {
    padding: 0;
    font-size: 110%;
}

.formNoPadding tr {
    padding: 0;
    font-size: 110%;
}

.formNoPadding th {
    padding: 0;
    font-size: 110%;
    font-weight: bold;
}

#debugging {
    display: none;
    border: 2px solid red;
    position: fixed;
    bottom: 0;
    width: 200px;
    height: 50px;
    background: #fff;
}

/* Reservedhours */
.user .reservedHours {
    width: 102%;
}

.reservedHours {
    min-width: 102%;
    max-width: 102%;
    color: #333;
    margin-left: -2%;
    border-top: none;
}

.reservedHours th {
    border-bottom: 1px solid #ccc;
}

.reservedHours th:first-child,
.reservedHours td:first-child {
    text-align: left;
    border-left: none;
    padding-left: 2%;
    min-width: 7%;
    max-width: 7%;
}

.reservedHours th:last-child,
.reservedHours td:last-child {
    border-right: 1px solid #ccc;
}

.reservedHours th,
.reservedHours td {
    min-width: 7%;
    max-width: 7%;
    padding: 5px 0;
}

/* Planning Month Table */
.planningMonthTable th,
.planningMonthTable td {
    border: 1px solid #bcbcbc;
    border-left: none;
    border-top: none;
    vertical-align: top;
}

.planningMonthTable td {
    text-align: left;
    position: relative;
    padding: 0;
    background: url(/images/backgrounds/planningMonthTableBg.gif) repeat-y right;
}

.planningMonthTable .tdContainer {
    position: relative;
    overflow: auto;
    padding: 5px;
    margin-bottom: 5px;
    min-height: 150px;
}

.planningMonthTable .tdContainer:hover {
    width: 250px;
}

.planningMonthTable .date {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #ccc;
    border-top: 0;
    border-left: 0;
    padding: 0 3px;
    font-weight: bold;
}

.planningMonthTable dl {
    margin-top: 20px;
    padding: 0;
    color: #999;
    font-style: italic;
}

.planningMonthTable dt {
    float: left;
    clear: both;
    width: 90%;
}

.planningMonthTable dd {
    float: right;
}

.planningMonthTable dt:first-child,
.planningMonthTable dd:first-child,
.planningMonthTable .thisWeek {
    margin-top: 0;
    color: #333;
    font-style: normal;
}

.planningMonthTable .total {
    font-weight: bold;
}

.planningThisWeek td {
    min-width: 16.6%;
    max-width: 16.6%;
}

/* Overflow & popupWrapper */
#overlay,
.ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    -moz-opacity: 0.4;
    -khtml-opacity: 0.4;
    background: #666;
    z-index: 2;
}

#overlay {
    display: none;
}

.popupContent {
    display: none;
    background: #fff;
    overflow: hidden;
    border: 1px solid #666;
    z-index: 10;
}

.popupPlanning {
    width: 805px;
    min-height: 300px;
    max-height: 450px;
    overflow-y: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -200px 0 0 -402px;
}

.popupContent form {
    margin: 0;
}

.greenGradientHeader {
    position: relative;
    width: 786px;
    border: none;
}

.popupContent .data {
    float: left;
    width: 805px;
}

.popupContent #popupPlanningTable {
    width: 100%;
    border-bottom: 1px solid #ccc;
}

.popupContent #popupPlanningTable .detail {
    font-size: smaller;
}

.popupContent #popupPlanningTable .red {
    color: #f00;
}

.popupContent #popupPlanningTable .blue {
    color: #00f;
}

.popupContent #popupPlanningTable .delUser {
    font-weight: bold;
    text-decoration: none;
    color: #666;
    margin: 0 0 0 2px;
}

.popupContent .data input,
.popupContent .data select {
    margin: 0.3em 0;
}

.popupContent .data select {
    width: 160px;
}

.popupContent .data td,
.popupContent .data th {
    padding: 3px;
    border-left: 1px solid #ccc;
    text-align: center;
    width: 12%;
}

.popupContent .data th:first-child,
.popupContent .data td:first-child {
    border: none;
    text-align: left;
    padding-left: 10px;
    width: 28%;
}

.popupContent .delete {
    display: block;
    float: right;
    width: 14px;
    height: 14px;
    background-position: -95px -25px;
    margin: 4px;
    text-indent: -3000em;
}

.popupContent .delete:hover {
    background-position: -110px -25px;
}

.popupContent .disabled {
    background: #f1f1f1;
}

.popupContent .disabled input {
    display: none;
}

.popupContent .disabled input[type=submit] {
    display: inline;
}

.popupContent .data input {
    width: 30px;
    text-align: center;
}

.popupContent .data input[type=submit] {
    width: 140px;
}

.popupContent .grayPrev {
    float: right;
    margin: 0;
}

.popupContent .grayNext {
    float: left;
    margin: 0;
}

.popupContent .info {
    float: left;
    width: 230px;
    margin: auto;
    padding-top: 25px;
}

.userDetail td {
    background: #666;
    color: #fff;
    padding: 7px;
}

.userIndent td {
    font-size: 90%;
    color: #666;
}

.popupContent .userIndent td:first-child {
    padding-left: 20px;
}

.popupContent .first td {
    padding-top: 10px;
}

.popupContent .last td {
    padding-bottom: 10px;
}

.popupContent .data .last td {
    border-bottom: 1px solid #ccc;
}

.popupContent .total td {
    height: 45px;
    border-top: 1px solid #ccc;
}

.popupContent .total td:first-child {
    border-top: 1px solid #ccc;
}

.borderBottom {
    border-bottom: 1px solid #ccc !important;
}
