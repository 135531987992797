/**
 * Button classes
 */

.btnOrange {
    display: block;
    background-image: none !important;
    background: $color-white;
    padding: 0 4px 0 6px;
    color: $color-secondary;
    font-weight: bold;
    height: 27px;
    line-height: 2.6;
    text-decoration: none;
    border: 2px solid $color-secondary;
    border-radius: 3px;
    cursor: pointer;

    &:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 5px solid transparent;
        border-left-color: $color-secondary;
        box-sizing: border-box;
        position: relative;
        bottom: -1px;
    }

    &:hover {
        border-color: darken($color-secondary,10%);
        color: darken($color-secondary,10%);
    }
}

.btnPrimary {
    //position: relative;
    //left: -12px;
    //display: inline-block;
    //padding: 6px 12px;
    //border-radius: 3px 0 0 3px;

    //

    background: $color-primary;
    border: 3px solid $color-primary;
    border-radius: 3px;
    padding: 3px;
    margin-left: 5px;
    display: inline-block;
    text-decoration: none;
    font-size: 11px;
    line-height: 1.45;
    font-weight: normal;
    color: white;
    cursor: pointer;

    &:hover {
        background: darken($color-primary,10%);
        border-color: darken($color-primary,10%);
        color: white;
    }
}

.newProject,
.newRelation {
    margin: 2px 2px 0 0;
    float: right;
}

.newProject {
    width: auto;
}

.noFrm .newProject {
    margin-top: 10px;
}

.saveNewProject {
    width: 80px;
    float: left;
    margin: 30px 0 0 304px;

    &:hover {
        -webkit-box-shadow: 0 0 8px 2px #aaa;
        -moz-box-shadow: 0 0 8px 2px #aaa;
        box-shadow: 0 0 8px 2px #aaa;
    }
}

.arrows {
    width: 23px;
    height: 16px;
    text-indent: -3000em;
    display: block;
    float: left;
    margin: 4px 0 0 5px;
    overflow: hidden;
}

.grayPrev {
    background-position: 0 -24px;

    &:hover {
        background-position: -46px -24px;
    }
}

.grayNext {
    background-position: -23px -24px;

    &:hover {
        background-position: -69px -24px;
    }
}

.grayUp {
    background-image: url(/images/icons/up.png);
}

.grayDown {
    background-image: url(/images/icons/down.png);
}
