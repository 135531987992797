/**
 * Top + main navigation
 */

#header {
    width: 100%;
    overflow: hidden;
}

/* top */
#top {
    background: #060000;
    padding: 24px 15px;

    a {
        color: #fff;
        text-decoration: none;

        &:hover {
            color: $color-primary;
        }
    }

    .welcome {
        float: right;
        color: #fff;
        line-height: 40px;

        span {
            float: left;
            color: #fff;
            margin-left: 15px;
        }
    }

    #messages {
        float: right;
        color: $color-red;
        margin-right: 130px;
    }

    .menu {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;

        li {
            float: left;
            height: 40px;
            line-height: 40px;

            a {
                display: block;
                text-align: center;
                margin-left: 16px;
                font-size: 14px;

                &.teamstats {
                    color: $color-orange;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &.logo {
                margin-right: $spacing-small;
            }
        }
    }
}

/* top nav --- are these actually being used? */
#topNav {
    li {
        margin-right: 10px;

        a {
            display: block;
            height: 22px;
            text-indent: -3000em;
            overflow: hidden;
        }
    }

    .planning {
        width: 60px;

        a:hover,
        .active {
            background-position: 0 -22px;
        }
    }

    .uren {
        width: 93px;
        background-position: -70px 0;

        a:hover,
        .active {
            background-position: -70px -22px;
        }
    }

    .projecten {
        width: 68px;
        background-position: -170px 0;

        a:hover,
        a.active {
            background-position: -170px -22px;
        }
    }

    .relaties {
        width: 58px;
        background-position: -246px 0;

        a:hover,
        .active {
            background-position: -246px -22px;
        }
    }

    .gebruikers {
        width: 76px;
        background-position: -396px 0;

        a:hover,
        .active {
            background-position: -396px -22px;
        }
    }

    .dashboard {
        width: 72px;
        background-position: -314px 0;

        a:hover,
        .active {
            background-position: -314px -22px;
        }
    }
}

/* sub */
.sub {
    height: auto;
    background: #999;
    background: -moz-linear-gradient(rgb(153, 153, 153) 20%, rgb(132, 132, 132) 80%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0.2, rgb(153, 153, 153)), color-stop(0.8, rgb(132, 132, 132)));
    color: #fff;
    padding: 7px 15px;
    overflow: hidden;

    form {
        margin: 0;
    }

    input,
    label,
    select {
        margin-right: 5px;
        float: left;
    }

    .checkbox {
        margin-right: 5px;
    }

    label.large {
        width: 110px;
    }

    .btnPrimary {
        float: right;
        padding: 3px;
        vertical-align: middle;
    }
}

/* subNav */
.subNav {
    padding: 15px;
    float: left;

    li {
        margin: 0 7px;
    }

    a {
        color: #fff;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.subExtraHeight {
    height: 80px;
}
