/**
* Button color mixin
*/

@mixin btn-color($property: 'background-color', $color: $color-base-button) {
	@include btn-hover-color($property, $color, 0.7);
	#{$property}: $color;

	&:disabled {
		&,
		&:focus,
		&:hover {
			#{$property}: rgba($color, 0.5);
		}
	}
}

@mixin btn-hover-color($property: 'background-color', $color: $color-base-button, $transparency: 1) {
	&:focus,
	&:hover {
		#{$property}: rgba($color, $transparency);
	}
}
