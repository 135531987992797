.color-primary {
	color: $color-primary;
}

.fill-primary {
	fill: $color-primary;
}

.bg-primary {
	background-color: $color-primary;
}

/* Status */
.status {
	display: block;
	width: 11px;
	height: 11px;
	text-indent: -3000em;
	margin: auto;
}

.statusGreen {
	background-position: 0 0;
}

.statusOrange {
	background-position: -11px 0;
}

.statusRed {
	background-position: -22px 0;
}