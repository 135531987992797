body {
	color: $color-base-font;
	font-family: $font-family-base;
	font-size: $font-size-base;
	line-height: $line-height-base;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-family-heading;
	font-weight: normal;
	font-size: $font-size-base;
	line-height: $line-height-heading;
	margin: 0 0 $spacing-small;
}

h1 {font-size: heading-scale(6)}
h2 {font-size: heading-scale(5)}
h3 {font-size: heading-scale(4)}
h4 {font-size: heading-scale(3)}
h5 {font-size: heading-scale(2)}
h6 {font-size: heading-scale(1)}

p {
	margin: 0 0 $spacing-small;
}

b,
strong {
	font-weight: bold;
}

a {
	color: $color-primary;
	fill: currentColor;
	transition: color $animation-base-duration $animation-base-timing;

	&:active,
	&:focus,
	&:hover {
		text-decoration: none;
	}
}

:link
{
	&:focus {
		outline: 0;
		box-shadow: 0 0 1px $color-primary;
	}
}

hr {
	border-bottom: $border-base;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	margin: $spacing-base 0;
}