/**
 * Projects
 */

/* Projects table */
.projectsTable {
	margin-bottom: 20px;
	border-bottom: 1px solid #ccc;
}

.projectsTable .expand td {
	border-bottom: 1px solid #ccc;
}

.projectsTable .red {
	color: $color-red;
}

.projectsTable .deployed {
	width: 13px;
	height: 13px;
}

/* projectDetailsTable */
.projectDetailsTable td {
	border-bottom: 1px solid #bcbcbc;
	position: relative;
	z-index: 1;
}

.projectDetailsTable .active {
	background-image: url(/images/backgrounds/activeWeek.png);
	background-position: center left;
	background-repeat: no-repeat;
}

.projectDetailsTable .days1 {
	background-position: 80% center;
}

.projectDetailsTable .days2 {
	background-position: 60% center;
}

.projectDetailsTable .days3 {
	background-position: 40% center;
}

.projectDetailsTable .days4 {
	background-position: 20% center;
}

.projectDetailsTable .days5 {
	background-position: 0% center;
}

.projectDetailsTable .first.days1 {
	background-position: -80% center;
}

.projectDetailsTable .first.days2 {
	background-position: -60% center;
}

.projectDetailsTable .first.days3 {
	background-position: -40% center;
}

.projectDetailsTable .first.days4 {
	background-position: -20% center;
}

.projectDetailsTable .first.days5 {
	background-position: 0% center;
}

.projectDetailsTable .thisWeek.stuffed {
	opacity: 1;
}

.projectDetailsTable .thisWeek {
	border: 2px solid #aaa;
	border-top: 0;
	border-bottom: 0;
	width: 21px;
}

.thisWeek {
	font-weight: bold;
	background: #e1e1e1;
}