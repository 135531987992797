/**
 * @section   : Global CSS
 * @project   : EIAv2
 * @author    : John van Hulsen <john@e-sites.nl>
 * @media     : all
 * @version   : 1.1
 */

/* Reset CSS */
blockquote,
body,
caption,
div,
dl,
dt,
dd,
form,
fieldset,
h1,
h2,
h3,
h4,
h5,
h6,
html,
label,
li,
ul,
ol,
p,
pre,
textarea,
table,
td,
th {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

/* html + body element */
html,
body {
    width: 100%;
    height: 100%;
    background: #fff;
    box-sizing: border-box;
}

body {
    font: normal 62.5%/1.5 Arial, sans-serif;
    color: #000;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

h1,
.h1 {
    font-size: 160%;
}

h2,
.h2 {
    font-size: 140%;
}

h3,
.h3 {
    font-size: 140%;
}

h4,
.h4 {
    font-size: 120%;
}

h5,
.h5 {
    font-size: 120%;
}

h6,
.h6 {
    font-size: 120%;
}

/* Paragraphs */
p {
    margin: 0 0 1.2em 0;
}

/* Anchors */
a {
    color: #333;
    text-decoration: underline;
}

a:focus,
a:hover {
    color: $color-primary;
}

a:active {
    outline: none;
}

/* Images */
img {
    border: none;
    margin: 0;
    padding: 0;
    display: block;
}

a img {
    border: none;
}

/* Lists */
ul {
    list-style-type: disc;
}

ol {
    list-style-type: decimal;
}

ul,
ol {
    margin: 0 0 1.2em 1.2em;
    padding: 0 0 0 1.2em;
}

li ul,
li ol {
    margin: 0 0 0 1em;
}

/* Semantic inline and block-level elements */
strong {
    font-weight: bold;
}

em {
    font-style: italic;
}

sup,
sub {
    line-height: 0;
}

address {
    font-style: normal;
}

hr {
    background-color: #dadada;
    border-width: 0;
    color: #dadada;
    height: 1px;
    line-height: 0;
}

/* Standard horizontal list */
.horizontal {
    list-style: none;
    margin: 0;
    padding: 0;
}

.horizontal li {
    position: relative;
    float: left;
}

/* Alternative flash content */
.flashObjPlaceholder {
    display: none;
}

/* Avoiding DOM flickering */
.hideIfJS {
    display: block;
}

#hasJS .hideIfJS {
    display: none;
}

.showIfJS {
    display: none;
}

#hasJS .showIfJS {
    display: block;
}

/* Cross-browser styling form elements */
form {
    margin: 1em 0;
    padding: 0;
    border: none;
}

form br {
    clear: left;
}

fieldset {
    margin: 0;
    border: none;
}

legend {
    font-weight: bold;
    border: none;
    display: none;
}

label {
    display: block;
    float: left;
    margin-top: 0.9em;
}

label,
.submit {
    cursor: pointer;
}

.rightLbl {
    width: auto;
    margin: 0.85em 1em 0 0.5em;
}

input,
select,
textarea {
    width: 110px;
    margin: 0.5em 0 0 0;
    padding: 0.4em;
    border: 1px solid #ccc;
    color: #555;
    font: 1em Arial, sans-serif;
}

select {
    height: 23px;
    width: 134px;
    padding: 0.2em;
}

textarea {
    height: 100px;
}

input[type=text]:focus,
select:focus,
textarea:focus {
    background: #f1f1f1;
}

input[type=submit] {
    cursor: pointer;
}

/*screw you and your one-line css, it's 2015 for crying out loud */

// button {
//     background: $color-primary;
//     border: 3px solid $color-primary;
//     -webkit-border-radius: 3px;
//     -moz-border-radius: 3px;
//     border-radius: 3px;
//     padding: $spacing-small;
//     margin-left: 5px;
//     display: inline-block;
//     text-decoration: none;
//     font-size: 11px;
//     font-weight: normal;
//     color: #fff;
//
//     &:hover {
//         background: transparent;
//         color: $color-primary;
//     }
// }
//
// button:hover,
// button:focus,
// button:active {
//     background: #777;
// }

button.fixed-width {
    width: 200px;
}

.radio,
.checkbox {
    position: relative;
    border: none;
    margin-top: 1em;
    line-height: 0;
    width: auto;
    background: none;
    float: left;
}

.submit {
    width: 234px;
    margin-left: 110px;
    padding: 0;
}

.addBtn {
    width: auto;
    display: block;
    margin: 10px 0 0 0;
    padding: 0 25px 0 5px;
    font-size: 11px;
    background-position: right -81px;
    height: 26px;
    color: #fff;
    border: 1px solid #fff;
}

a.addBtn {
    display: block;
    width: 150px;
    line-height: 26px;
    text-decoration: none;
    color: #fff;
}

.labelLeft {
    margin-left: 140px;
}

.roundedCorners {
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
}

.boxShadow {
    -webkit-box-shadow: 0px 0px 8px #999;
    -moz-box-shadow: 0px 0px 8px #999;
    box-shadow: 0px 0px 8px #999;
}

/* Error message handling */
p.error,
p.success {
    padding: 10px;
    font-weight: bold;
    color: #f00;
    border: 1px solid #f00;
    background: #fff0f0;
}

p.success {
    border: 1px solid #285629;
    background: #c9e7ca;
    color: #285629;
}

p.succes {
    padding: 5px;
    color: #285629;
    border: 1px solid #285629;
    background: #c9e7ca;
}

label.error {
    color: #f00;
    font-weight: bold;
}

input.error {
    border: 1px solid #f00;
}

span.error {
    color: #f00;
    font-weight: bold;
}

/* cleanUpContent fix */
.text-align-left {
    text-align: left !important;
}

.text-align-right {
    text-align: right;
}

.text-align-center {
    text-align: center;
}

.text-align-justify {
    text-align: justify;
}

/* Tables */
table {
    border-collapse: collapse;
}

table th,
table td {
    padding: 5px 15px;
}

table.smallPadding th,
table.smallPadding td {
    padding: 5px;
}

table.smallPadding th:first-child,
table.smallPadding td:first-child {
    padding-left: 15px;
}

table th {
    text-align: left;
}

table .even {
    background: #eee;
}

table .icon {
    vertical-align: text-bottom;
}

.fullTable {
    width: 100%;
    margin-left: -15px;
}

table .thead th {
    border-bottom: 1px solid #bcbcbc;
}

table .tfoot td {
    border-top: 1px solid #bcbcbc;
}

.tdWidth30 {
    width: 30px;
}

.tdWidth40 {
    width: 40px;
}

.tdWidth50 {
    width: 50px;
}

.tdWidth60 {
    width: 60px;
}

.tdWidth65 {
    width: 65px;
}

.tdWidth70 {
    width: 70px;
}

.tdWidth85 {
    width: 85px;
}

.tdWidth100 {
    width: 100px;
}

.tdWidth120 {
    width: 120px;
}

.tdWidth140 {
    width: 140px;
}

.tdWidth150 {
    width: 150px;
}

.tdWidth160 {
    width: 160px;
}

.tdWidth170 {
    min-width: 170px;
    max-width: 170px;
}

.tdWidth180 {
    width: 180px;
}

.tdWidth250 {
    width: 250px !important;
}

.tdWidth270 {
    width: 270px;
}

.tdWidth400 {
    width: 400px !important;
}

.tdWidth25p {
    width: 25%;
}

.tdWidth40p {
    width: 40%;
}

.tableWidth100p {
    width: 100%;
}

.weekNumber {
    width: 22px;
    padding: 0;
    border: 1px solid #bcbcbc;
    border-top: none;
    text-align: center;
}

.totalHours {
    width: 22px;
    padding: 0;
    text-align: left;
}

/* Global classes */
.first {
    margin-left: 0;
    padding-left: 0;
}

.last {
    margin-right: 0;
    padding-right: 0;
}

.top {
    margin-top: 0;
    padding-top: 0;
}

.bottom {
    margin-bottom: 0;
    padding-bottom: 0;
}

.inline {
    display: inline;
}

.center {
    margin: 0 auto;
    text-align: center;
}

.clear {
    clear: both;
}

.clearfix:before,
.clearfix:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
}

.clearfix:after {
    clear: both;
}

.lt-ie9 .clearfix {
    zoom: 1;
}

.floatLeft {
    float: left;
}

.floatRight {
    float: right;
}

.imgLeft {
    float: left;
    margin: 4px 10px 4px 0;
}

.imgRight {
    float: right;
    margin: 4px 0 4px 10px;
}

.strong {
    font-weight: bold;
}

.emphasis {
    font-style: italic;
}

.underline {
    border-bottom: 1px solid;
}

.marginLeft1px {
    margin-left: 1px;
}

.marginLeft5px {
    margin-left: 5px;
}

.hide {
    display: none;
}

.noBg {
    background: none;
}

.noMargin {
    margin: 0;
}

.noPadding {
    padding: 0;
}

.noIndent {
    margin-left: 0;
    padding-left: 0;
}

.noBullet {
    list-style: none;
    list-style-image: none;
}

.moreMargin {
    margin-top: 10px;
    margin-bottom: 10px;
}

.placehoder {
    color: #555;
}

abbr {
    border-bottom: 1px dotted #000;
}

span.warning {
    font-weight: 600;
    color: #ff0000;
}
