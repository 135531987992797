//@import 'node_modules/bourbon-neat/app/assets/stylesheets/_neat.scss';

// Neat Overrides
$column: 65px;
$gutter: 30px;
$grid-columns: 12;
$max-width: 74em;

// Neat Breakpoints
$sm-width: 30em;
$md-width: 40em;
$lg-width: 60em;
$xl-width: $max-width;

/* Max widths of the various breakpoints */
$xs-max-width: ($sm-width - 0.01);
$sm-max-width: ($md-width - 0.01);
$md-max-width: ($lg-width - 0.01);
$lg-max-width: ($xl-width - 0.01);

$sm-up: new-breakpoint(min-width $sm-width);
$md-up: new-breakpoint(min-width $md-width);
$lg-up: new-breakpoint(min-width $lg-width);
$xl-up: new-breakpoint(min-width $xl-width);

$xs-down: new-breakpoint(max-width $xs-max-width);
$sm-down: new-breakpoint(max-width $sm-max-width);
$md-down: new-breakpoint(max-width $md-max-width);
$lg-down: new-breakpoint(max-width $lg-max-width);
