/**
 * Dashboard
 */

/* Dashboard */
#dashboardMessages {
	margin: 0;
	padding: 5px 15px;
	list-style: none;
}

#dashboardMessages li {
	margin: 10px 0 0 0;
}

#dashboardMessages span {
	background: #a8c4bc;
	padding: 5px 25px 5px 5px;
	position: relative;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

#dashboardMessages .closeMessage {
	width: 14px;
	height: 14px;
	position: absolute;
	top: 5px;
	right: 8px;
	background-position: -126px -25px;
	text-indent: -3000em;
}

.dashboardInfo h1,
.dashboardInfo h2 {
	padding: 10px 0 5px 15px;
	display: block;
}

.dashboardInfo .btnRight {
	padding: 0 120px 0 0;
	position: relative;
}

.dashboardInfo .goTo {
	background: $color-primary;
	border: 3px solid $color-primary;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	padding: $spacing-small;
	margin-left: 5px;
	display: inline-block;
	text-decoration: none;
	font-size: 11px;
	font-weight: normal;
	color: #fff;

	&:hover {
		background: transparent;
		color: $color-primary;
	}
}

hr.clear {
	margin: 10px 0 10px 0;
}

/* horizontalBar */
.horizontalBarWrapper {
	overflow-x: auto;
	overflow-y: hidden;
	margin: 10px 0;
}

.horizontalBar {
	white-space: nowrap;
}

.userCol {
	width: 120px;
	float: left;
	padding: 10px;
	border-right: 1px solid #bcbcbc;
	margin-bottom: -1000px;
	padding-bottom: 1010px;
}

.userCol .red {
	color: $color-red;
}

.userCol .orange {
	color: $color-orange;
}

.userCol input[type=checkbox] {
	float: right;
	clear: right;
	width: auto;
	margin: 2px 0 2px 0;
}

.horizontalBar .spacer {
	width: 51px;
	height: 10px;
	float: left;
}

/* zzDay */
.zzDay {
	padding: 5px;
	font-size: 110%;
	border: 1px solid #bcbcbc;
	margin: 10px 0;
}

.zzDay .largeDate {
	font-size: 160%;
	line-height: 100%;
	float: right;
	color: #aaa;
}

.zzDay .hour {
	width: 80px;
	display: inline-block;
	padding-left: 5px;
	line-height: 190%;
}

.zzDay .gray {
	color: #666;
}

/* User */
#newPlanningrule {
	display: none;
}