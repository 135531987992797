/**
 * Calendar
 */

#leftContent .wc-header,
#rightContent .header {
	background: #efefef;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgb(245, 245, 245)), color-stop(1, rgb(233, 233, 233)));
	background: -moz-linear-gradient(center top, rgb(245, 245, 245) 0%, rgb(233, 233, 233) 100%);
	height: 32px;
	border-bottom: 1px solid #bcbcbc;
	width: 100%;
	font-size: 11px;
	table-layout: fixed;
}

#rightContent .header {
	line-height: 30px;
	height: 31px;
	display: block;
	text-align: center;
	position: relative;
}

#rightContent .arrows {
	position: absolute;
	top: 3px;
}

#rightContent .grayPrev {
	left: 3px;
}

#rightContent .grayNext {
	right: 3px;
}

#leftContent .wc-header td {
	width: 13.5%;
}

#leftContent .wc-header td:first-child {
	width: 4%;
}

#leftContent .wc-header .wholeWeek {
	width: 6% !important;
}

#leftContent .wc-header .wc-scrollbar-shim {
	width: 0;
}

/* Calendar grid */
.wc-container {
	margin-top: -17px;
}

.wc-nav .wc-prev,
.wc-nav .wc-next {
	position: absolute;
	margin-top: 5px;
	width: 23px;
	height: 16px;
	border: none;
	text-indent: -3000em;
	font-size: 0;
	cursor: pointer;
}

.wc-nav .wc-prev {
	left: 30px;
	background-position: 0 -24px;
}

.wc-nav .wc-prev:hover {
	background-position: -46px -24px;
}

.wc-nav .wc-next {
	right: 18%;
	background-position: -23px -24px;
	z-index: 255;
}

.wc-nav .wc-next:hover {
	background-position: -69px -24px;
}

.wc-scrollable-grid {
	overflow: hidden;
	overflow-x: hidden !important;
	overflow-y: auto !important;
	position: relative;
	background-color: #fff;
	width: 100%;
}

.wc-header .wc-today {
	font-weight: bold;
}

.wc-time-slots .wc-today {
	background-color: #e7edf3;
}

.wc-day-column-header {
	height: 30px;
	text-align: center;
}

.wc-header .wc-time-column-header {
	width: 6%;
}

.wc-header .wc-scrollbar-shim {
	width: 16px;
}

.wc-day-column {
	width: 13.5%;
	border-left: 1px solid #ddd;
	overflow: visible;
	vertical-align: top;
}

.wc-day-column-inner {
	width: 100%;
	position: relative;
}

.wc-time-slot-wrapper {
	position: relative;
	height: 1px;
	top: 1px;
}

.wc-time-slots {
	position: absolute;
	width: 100%;
}

.wc-time-header-cell {
	padding: 5px;
	height: 80px;
}

.wc-time-slot {
	border-bottom: 1px dotted #ddd;
}

.wc-hour-header {
	text-align: right;
}

.wc-hour-end, .wc-hour-header {
	border-bottom: 1px solid #ccc;
	color: #555;
}

.wc-business-hours {
	background-color: #E6EEF1;
	border-bottom: 1px solid #ccc;
	color: #333;
	font-size: 1.4em;
}

.wc-time-slot-header .wc-header-cell {
	text-align: right;
	padding-right: 10px;
}

.wc-cal-event {
	-moz-border-radius: 5px;
	border-radius: 5px;
	position: relative;
	z-index: 3;
}

.wc-cal-event div {
	padding: 0 5px;
}

.wc-container .ui-draggable .wc-time {
	cursor: move;
}

.wc-cal-event .wc-title {
	position: relative;
}

.wc-container .ui-resizable-s {
	position: absolute;
	width: 95%;
	height: 10px;
	bottom: 0;
	cursor: s-resize;
}

.wc-cal-event:hover .ui-resizable-s {
	background: url(/images/backgrounds/calendarResize.png) center no-repeat;
}

.wc-container .ui-draggable-dragging {
	z-index: 1000;
}

table.wc-time-slots {
	width: 100%;
	table-layout: fixed;
	cursor: default;
}

.wc-grid-timeslot-header {
	width: 6%;
	background: #eee;
}

.wc-day-header-cell {
	vertical-align: middle;
	padding: 4px;
}

.wc-time-header-cell {
	font-size: 11px;
	background: #efefef;
}

.wc-business-hours .wc-time-header-cell {
	background: #fff;
}

.wc-container table {
	border-collapse: collapse;
	border-spacing: 0;
}

.wc-container table td {
	margin: 0;
	padding: 0;
}

.wc-day-column .wc-cal-event {
	background-color: #fff;
	border: 1px dashed #666; /* filter:alpha(opacity=90); -moz-opacity:0.9; -khtml-opacity:0.9; opacity:0.9; */
	position: absolute;
	margin: -1px 0 0 -1px;
	text-align: center;
	overflow: hidden;
	cursor: pointer;
	color: #fff;
	width: 99%;
	font-size: 11px !important;
	background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.12)), -moz-linear-gradient(90deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0)) !important;
	background-position: left top, left bottom;
	background-size: 100% 15px, 100% 150px;
	background-repeat: repeat-x;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.65) inset;
}

.wc-cal-event .wc-time {
	background: #fff;
	color: #666;
	text-align: left;
	border-bottom: 1px solid #666;
	padding: 5px;
}

.wc-cal-event .wc-title {
	color: #666;
	text-align: left;
	padding: 4px;
	line-height: 15px;
}

.wc-day-column .ui-draggable {
	background: #666;
	border: none;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
}

.ui-draggable .wc-time {
	background: none;
	color: #fff;
	border-bottom: none;
}

.ui-draggable .wc-title {
	color: #fff;
	line-height: 14px;
}

.ui-draggable .trans {
	font-size: 90%;
	color: #fff;
	filter: alpha(opacity=70);
	-moz-opacity: 0.7;
	-khtml-opacity: 0.7;
	opacity: 0.7;
}

.ui-draggable .time {
	position: absolute;
	bottom: -10px;
	right: 5px;
	display: none;
}

.wc-cal-event:hover .time {
	display: block;
}

/* Add/edit activity window */
.ui-dialog {
	width: 500px !important;
}

#event_edit_container {
	border: 1px solid #676767;
	background: #fff;
	display: none;
	width: 400px;
	padding: 7px 0 35px 0;
}

#event_edit_container form {
	margin: 0;
}

.ui-dialog-titlebar /* Dit is eigenlijk de h2 */
{
	width: 478px;
	cursor: move;
}

#event_edit_container .sub {
	height: 25px;
	padding: 0 0 5px 10px;
}

#event_edit_container .timeBar {
	background: #f5f5f5;
	border-bottom: 1px solid #666;
	height: 30px;
	padding-left: 10px;
}

#event_edit_container .timeBar label {
	width: 130px;
}

#event_edit_container .regular {
	padding: 10px;
	margin-bottom: 10px;
	margin: auto;
}

#event_edit_container .regular input,
#event_edit_container .regular select,
#event_edit_container .regular textarea {
	float: none;
	width: 465px;
}

#event_edit_container .regular select {
	width: 475px;
}

#event_edit_container .regular textarea {
	width: 465px;
}

#event_edit_container input {
	width: 400px;
}

#event_edit_container .checkbox {
	width: auto;
}

#event_edit_container .small {
	width: 60px;
	margin-left: 5px;
	margin-right: 5px;
	float: left;
}

#event_edit_container #loader {
	position: absolute;
	left: 14px;
	bottom: 14px;
}

.ui-dialog-buttonpane {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.ui-dialog-buttonpane button {
	background: url(/images/sprites/btn.png) 0 -40px no-repeat;
	cursor: pointer;
	border: none;
	text-align: left;
	width: 96px;
	height: 24px;
	font-size: 13px;
	font-weight: bold;
	padding: 0 0 3px 17px;
	color: #fff;
	text-transform: lowercase;
	margin-left: 5px;
}

#event_edit_container .regular input.small {
	width: 360px;
	margin-left: 0;
}

#event_edit_container .regular .butonSizeSmall {
	margin: 0 7.5px 0 7.5px;
	width: 85px;
}

#zdErrorField {
	margin: 5px 0 5px 0;
}

/* calendarTable */
.calendarTable {
	margin: auto;
}

.calendarTable th,
.calendarTable td {
	padding: 0;
	text-align: center;
}

.calendarTable th {
	padding: 7px 0;
	text-transform: uppercase;
}

.calendarTable a {
	padding: 5px 8px;
	display: block;
	border: 1px solid #fff;
	color: #333;
	background: #fff;
	text-decoration: none;
}

.calendarTable a:hover,
.calendarTable .weekend a:hover,
.calendarTable .selectedWeek a:hover {
	border: 1px solid #ccc;
}

.calendarTable .otherMonth a {
	color: #999;
}

.calendarTable .selectedWeek a {
	background: #fff0d9;
	border: 1px solid #fff0d9;
}

.calendarTable .weekend a {
	background: #f1f1f1;
	border: 1px solid #f1f1f1;
}

.calendarTable .today a {
	background: #bcbcbc;
	color: #fff;
	border: 1px solid #bcbcbc;
	font-weight: bold;
}

.calendarTable .week {
	font-size: 80%;
	color: #666;
	padding: 0 5px;
}
