/**
 * @section   : Lay-out CSS
 * @project   : EIAv2
 * @author    : John van Hulsen <john@e-sites.nl>
 * @media     : all
 * @version   : 1.0
 */

/* Global table sets */
table tr.active {background:#666; color:#fff;}
table tr.active a {color:#fff;}
table .last td {border-bottom:1px solid #777600;}


/* statusmailconfig */
#statusmailconfigWrapper{margin:50px;}
#statusmailconfigWrapper .groupDiv{margin-top:50px;}
#statusmailconfigWrapper #statusmailconfigForm label{width:auto;}
#statusmailconfigWrapper #statusmailconfigForm input[type=text]{margin-left:40px;width: 250px;}


/* Status bar */
.statusBox {margin:10px auto; width:210px;}
.statusBar {width:210px; height:10px; background:#fff; text-indent:-3000em; margin:5px 0; position:relative; overflow:hidden;}
.statusBar .block {position:absolute; top:0; left:0; display:block; height:10px; width:100%;}
.statusBar .red {background:#f00;}
.statusBar .orange {background:#fc0;}
.statusBar .green {background:#6c0;}

.statusBox .sum {border-bottom:1px solid black;}
.statusBox td, .statusBox th {padding:0 6px 3px 0;}
.statusBox th {padding-top:5px;}
.statusBox .projectName {width:300px;}
/* Autocomplete */
.ac_results {padding:0px; border:1px solid #ccc; background-color:Window; overflow-x:hidden; max-height:250px;}
.ac_results iframe {display:none;}
.ac_results ul {width:100%; list-style-position:outside; list-style:none; padding:0; margin:0;}
.ac_results li {margin:0px; padding:0 5px; cursor:pointer; display:block; width:350px; font-size:11px; overflow:hidden; height:18px; line-height:18px;}
.ac_over {background-color:Highlight; color:HighlightText;}
.ac_loading {background:url(/images/misc/loader.gif) no-repeat right;}

/* Datepicker */
#ui-datepicker-div {background:#fff; border:1px solid #ccc; display:none; z-index:255;}
.ui-datepicker {width:17em; padding:0.2em 0.2em 0;}
.ui-datepicker .ui-datepicker-header {position:relative; padding:0.2em 0; background:#a4a400; color:#fff; height:25px; font-size:12px;}
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {position:absolute; top:4px; width:1.8em; color:#fff; height:25px; cursor:pointer; text-align:center; font-size:13px; text-decoration:none; font-weight:bold;}
.ui-datepicker .ui-datepicker-next {right:0;}
.ui-datepicker .ui-datepicker-title {margin:0 2.3em; line-height:25px; text-align:center;}
.ui-datepicker .ui-datepicker-title select {font-size:12px; margin:1px 0;}
.ui-datepicker select.ui-datepicker-month-year {width:100%;}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {width:49%;}
.ui-datepicker table {width:100%; font-size:11px; border-collapse:collapse; margin:0 0 0.4em;}
.ui-datepicker th {padding: 0.7em 0.3em; text-align:center; font-weight:bold; border:0;}
.ui-datepicker td {border:0; padding:1px;}
.ui-datepicker td span, .ui-datepicker td a {display:block; padding:0.2em; text-align:right; text-decoration:none;}
.ui-datepicker .ui-datepicker-buttonpane {background-image:none; margin:0.7em 0 0 0; padding:0 0.2em; border-left:0; border-right:0; border-bottom:0;}
.ui-datepicker .ui-datepicker-buttonpane button {float:right; margin:0.5em 0.2em 0.4em; cursor:pointer;padding:0.2em 0.6em 0.3em 0.6em; width:auto; overflow:visible;}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current { float:left;}
.ui-datepicker-calendar td a {border:1px solid #eee; color:#666; text-align:center;}
.ui-datepicker-calendar td a:hover {background:#ccc;}
.ui-datepicker-calendar td .ui-state-active {background:#a4a400; color:#fff;}
.ui-datepicker-calendar td .ui-state-active:hover {background:#ffa164; border:1px solid #a4a400;}

/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {width:auto;}
.ui-datepicker-multi .ui-datepicker-group {float:left;}
.ui-datepicker-multi .ui-datepicker-group table {width:95%; margin:0 auto 0.4em;}
.ui-datepicker-multi-2 .ui-datepicker-group {width:50%;}
.ui-datepicker-multi-3 .ui-datepicker-group {width:33.3%;}
.ui-datepicker-multi-4 .ui-datepicker-group {width:25%;}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header {border-left-width:0;}
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {border-left-width:0;}
.ui-datepicker-multi .ui-datepicker-buttonpane {clear:left;}
.ui-datepicker-row-break {clear:both; width:100%;}


.day-1,
.day-2,
.day-3,
.day-4,
.day-5 {background:url(/images/backgrounds/pauze.png) 0 235px  repeat-x;}
.hours24 {background-position:0 572px;}
.day-6,
.day-7 {background:#eee;}

/* Add newbase numbers */
.newbasenrs {float:left; margin:0;}
.small-submit {float:left; background-position:-23px -24px; width:23px; height:16px; display:block; text-indent:-9999em; margin:4px 0 0 5px;}

/* Forms */
#nieuwProjectFrm,
#projectDetails {padding-bottom:15px;}
#nieuwProjectFrm label,
#projectDetails label {width:110px; margin-left:5px; margin-right:15px;}
textarea.large,
input.large {width:375px;}
select.large {width:387px;}
input.medium,
textarea.medium {width:300px;}
textarea.medium {height:50px;}
select.medium {width:312px;}
input.xxs {width:50px;}
select.xxs {width:50px;}
input.xs {width:45px;}
select.xs {width:60px;}
input.small {width:125px;}
select.small {width:90px;}
select.smallish {width:80px;}
input.smaller {width:60px;}
select.smaller {width:60px;}
input.smallHours {width:17px;}
input.micro {width:8px; padding:auto 5px; text-align:center;}
input.mini {width:23px; font-size:10px; padding:5px 3px;}
input.projectFilter { width:123px; }
input.hoursChecked { padding:0; border:0; margin:0;  }

/* Sprites */
#topNav li,
#topNav li a:hover,
#topNav li .active {background:url(/images/sprites/nav.png) no-repeat;}
.base {background-image:url(/images/sprites/base.png);}
.btn {background-image:url(/images/sprites/btn.png);}

.available {color:blue;}
.unavailable {color:red;}

/* timeregistration > maintenance hours overview */
.currentTimeLine {display:none; position:absolute; top:0; border-top:1px dotted #333; width:100%; z-index:250; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; filter: alpha(opacity=50); -moz-opacity:0.5; -khtml-opacity: 0.5; opacity: 0.5;
}
.ie .currentTimeLine {display:block;}

.loaderDiv {position:absolute; width:80%; height:610px; left:0; top:156px; z-index:555; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; filter: alpha(opacity=50); -moz-opacity:0.5; -khtml-opacity: 0.5; opacity: 0.5; background:#fff url(/images/misc/largeLoader.gif) center center no-repeat; display:none;}


tr.maintenance { color:blue;}

.selectedTimeRegistration {position:absolute; top:91px; left:250px;}
.indenting {width:16px; float:left;}

.relationContactList {clear: both;margin: 0 0 0 20px;padding: 10px 0 0;}

ul.facturatieSub {
	list-style-type: none;
	margin: 0;
	margin-left: 30px;
	padding: 0;
	height: 50px;
	line-height: 50px;
	vertical-align: middle;
}

ul.facturatieSub li {
	float: left;
	margin-left: 10px;
	margin-right: 10px;
}

ul.facturatieSub li a {
	color: #ffffff;
	text-decoration: none;
}

ul.facturatieSub li a:hover {
	text-decoration: underline;
}

.maintenanceTotals table {
	border-spacing: 0;
	border: 0px;
	float: right;
}

#shareRegistrationBox {
	label {
		display: inline-block;
		float: none;
		margin-left: 5px;
		margin-top: 5px;
	}
	input[type="checkbox"] {
		display: inline;
		float: left;
		width: auto;
	}

}

.year-and-week-selector{
	margin-right:40px;
}
#header .sub label.label-small{
	width: 50px;
	text-align:right;
}
.year-and-week-selector .small-date-select{
	width:60px;
}
.clearBlock {
	clear: both;
}

.maintenanceTotals tr.hoursRemaining {
	border-top: 1px solid #000000;
}

.maintenanceTotals tr td {
	text-align: right;
}

.pdfGenerationForm {width: 700px;}
.pdfGenerationForm textarea {width: 688px; height: 220px; display: block;}
.pdfGenerationForm input[type="file"] {width: 568px;display: inline; height: 24px;}
.pdfGenerationForm input[id="uploadAttachment"] { float: right; height: 36px; display: inline-block;}
.pdfGenerationForm input[type="submit"] {float: right; height: 36px;}

.pdfGenerationProgress, .csvGenerationProgress, .quarterOverviewGenerationProgress, .monthOverviewGenerationProgress, .ecreditsOverviewGenerationProgress {clear: both;}
.pdfGenerationProgress .progress, .csvGenerationProgress .progress, .quarterOverviewGenerationProgress .progress, .monthOverviewGenerationProgress .progress, .ecreditsOverviewGenerationProgress .progress {display: none;}
#quarterOverviewGenerateMissingData {display: none;}

#pdfGenerateStatus img, #csvGenerateStatus img, #quarterOverviewGenerateStatus img {display: inline;}
.pdfLoading, .csvLoading, .quarterOverviewLoading {color: orangered;}

#submitQuarterOverviewGenerate {width: 166px; margin-right: 3px;}
#submitCsvGenerate {margin-left: 3px;}