/**
 * Content
 */

#content {
	padding: 15px;
	height: 100% !important;
}

#content.gray {
	background: #e6e6e6 url(/images/backgrounds/grayBg.png) repeat-y 80%;
	padding: 0;
}

#content.noPadding {
	padding: 0;
}

#content h1 {
	font-size: 16px;
	color: $color-dark;
	float: left;
}

#content h1 .edit {
	font-size: 12px;
	font-weight: normal;
	padding: 3px;
	vertical-align: middle;
	color: #999;
}

#leftContent {
	width: 80%;
	float: left;
	overflow-x: hidden;
}

#rightContent {
	float: left;
	display: block;
	width: 20%;
}

.white {
	background: #fff;
}

.overflow {
	overflow: auto;
}

.threeCols {
	clear: both;
	margin-top: 20px;
}

.threeCols .col {
	width: 33%;
	min-width: 390px;
	float: left;
	position: relative;
}

.twoCols {
	clear: both;
}

.col {
	font-size: 110%;
}

.col dt {
	float: left;
	width: 140px;
	padding: 5px 0;
}

.col .activityHours dt {
	width: 260px;
}

.col dd {
	padding: 5px 0;
}

.col dd .red {
	color: $color-red;
}

.col h2 {
	padding: 15px 0 5px 0;
}

.gradientHeader {
	background: #efefef;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgb(245, 245, 245)), color-stop(1, rgb(233, 233, 233)));
	background: -moz-linear-gradient(center top, rgb(245, 245, 245) 0%, rgb(233, 233, 233) 100%);
}

.greenGradientHeader,
.ui-dialog-titlebar {
	position: absolute;
	height: 18px;
	border: 1px solid #666;
	margin: 1px;
	font-size: 12px;
	color: #fff;
	padding: 5px 10px;
	font-weight: bold;
	margin: 0;
	background: #caca00;
	background: -moz-linear-gradient(center top, rgb(202, 202, 0) 0%, rgb(155, 155, 0) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0.2, rgb(202, 202, 0)), color-stop(0.8, rgb(155, 155, 0)));
}

.ui-icon-closethick,
.close {
	position: absolute;
	right: 10px;
	top: 4px;
	color: #fff;
	text-decoration: none;
	font-weight: bold;
}

.close:hover {
	color: #fff;
}

/* Two cols - details */
.twoCols {
	border-bottom: 1px solid #ccc;
	margin-left: -15px;
	margin-right: -15px;
	overflow: hidden;
}

.twoCols .col {
	float: left;
	padding-left: 15px;
	width: 40%;
	border: 1px solid #ccc;
	border-bottom: none;
	border-right: none;
	margin-bottom: -1000px;
	padding-bottom: 1010px;
}

.twoCols .col h2 {
	width: 35%;
}

.twoCols .col table {
	font-size: 11px;
}

.twoCols .col table h2 {
	width: auto;
}

.twoCols .col:nth-child(odd) {
	border-left: none;
}

.twoCols .selectOption {
	float: right;
	position: relative;
	margin-top: -30px;
	margin-right: 10px;
	width: 60%;
}

.twoCols .halfWidthFullTable {
	margin-left: -15px;
	margin-bottom: 5px;
	border-bottom: none;
}

#customerDetails .fullTable {
	margin-right: -15px;
}

.smallFont {
	font-size: 100%;
}